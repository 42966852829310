import React from 'react';

import css from './ConditionDescription.module.css';
import { FormattedMessage } from 'react-intl';

// TODO: Descriptions for each condition
const ConditionDescription = _props => {
  return (
    <div className={css.container}>
      <div className={css.item}>
        <div className={css.itemHeading}>
          <h4>
            <FormattedMessage id="ConditionDescription.likeNewTitle" />
          </h4>
          <p>
            <FormattedMessage id="ConditionDescription.likeNewCount" />
          </p>
        </div>

        <p>
          <FormattedMessage id="ConditionDescription.likeNewDescription" />
        </p>
      </div>

      <div className={css.item}>
        <div className={css.itemHeading}>
          <h4>
            <FormattedMessage id="ConditionDescription.excellentTitle" />
          </h4>
          <p>
            <FormattedMessage id="ConditionDescription.excellentCount" />
          </p>
        </div>

        <p>
          <FormattedMessage id="ConditionDescription.excellentDescription" />
        </p>
      </div>

      <div className={css.item}>
        <div className={css.itemHeading}>
          <h4>
            <FormattedMessage id="ConditionDescription.goodTitle" />
          </h4>
          <p>
            <FormattedMessage id="ConditionDescription.goodCount" />
          </p>
        </div>

        <p>
          <FormattedMessage id="ConditionDescription.goodDescription" />
        </p>
      </div>

      <div className={css.item}>
        <div className={css.itemHeading}>
          <h4>
            <FormattedMessage id="ConditionDescription.wellRiddenTitle" />
          </h4>
          <p>
            <FormattedMessage id="ConditionDescription.wellRiddenCount" />
          </p>
        </div>
        <p>
          <FormattedMessage id="ConditionDescription.wellRiddenDescription" />
        </p>
      </div>
    </div>
  );
};

export default ConditionDescription;
