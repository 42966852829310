import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, propTypes } from '../../util/types';
import { IconInfo, Modal } from '..';

import css from './OrderBreakdown.module.css';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount >= 0
  );
};

const BuyerProtectFeeMarkdown = () => (
  <>
    <h3>Moose Buyer Protection🛡</h3>
    <p>
      Buying used bikes online is not easy. That's why we offer Buyer Protection on all our bikes.
    </p>
    <h4>Our Promise</h4>
    <p>
      Moose securely stores your money until you have received and for 24 hours until you approve
      the bike.
    </p>
    <p>Every shipment is insured against damages and loss during shipping.</p>
    <p>
      If the features or conditions of the bike vastly differ from the description, you can return
      the bike for free. Once reported within 24 hours of delivery, Moose Marketplace will take care
      of sending the bike back.
    </p>
    <h4>Support</h4>
    <p>
      The Moose Marketplace Team are always here to help resolve any discrepancies with the bike you
      have purchased. You can contact us on <a href="mailto:help@moose.co">help@moose.co</a>.
    </p>
  </>
);

const LineItemCustomerCommissionMaybe = props => {
  const { intl, isCustomer, lineItems, marketplaceName } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const customerCommissionLineItem = lineItems.find(
    item => item.code === LINE_ITEM_CUSTOMER_COMMISSION && !item.reversal
  );

  // If commission is passed it will be shown as a fee already added to the total price
  let commissionItem = null;

  if (isCustomer && customerCommissionLineItem) {
    if (!isValidCommission(customerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', customerCommissionLineItem);
      throw new Error('Commission should be present and the value should be zero or positive');
    }

    const commission = customerCommissionLineItem.lineTotal;
    const formattedCommission = commission ? formatMoney(intl, commission) : null;

    commissionItem = (
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage
            id="OrderBreakdown.commission"
            values={{ marketplaceName, role: 'customer' }}
          />

          <button
            className={css.tooltipTrigger}
            onClick={() => setModalOpen(prev => !prev)}
            type="button"
          >
            <IconInfo colour="#f04f36" />
          </button>
        </span>
        <span className={css.itemValue}>{formattedCommission}</span>

        <Modal
          className={css.commissionModal}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onManageDisableScrolling={() => {}}
        >
          <BuyerProtectFeeMarkdown />
        </Modal>
      </div>
    );
  }

  return commissionItem;
};

LineItemCustomerCommissionMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isCustomer: bool.isRequired,
  marketplaceName: string.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionMaybe;
