import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconError.module.css';

const IconError = props => {
  const { className, colour = '#413c3c', rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2215_12)">
        <g clipPath="url(#clip1_2215_12)">
          <path
            d="M0.894427 14.8882L7.77639 1.12426C7.86853 0.94 8.13148 0.940003 8.22361 1.12426L15.1056 14.8882C15.1887 15.0544 15.0678 15.25 14.882 15.25H1.11803C0.932187 15.25 0.811315 15.0544 0.894427 14.8882Z"
            fill="none"
            stroke={colour}
            strokeWidth="1.5"
          />
          <path
            d="M8 5.5L8 10.5"
            fill="none"
            stroke={colour}
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <path
            d="M8.8 12.6C8.9259 12.8518 8.9259 13.1482 8.8 13.4C8.47038 14.0592 7.52962 14.0592 7.2 13.4C7.0741 13.1482 7.0741 12.8518 7.2 12.6C7.52962 11.9408 8.47038 11.9408 8.8 12.6Z"
            fill={colour}
          />
        </g>
      </g>

      <defs>
        <clipPath id="clip0_2215_12">
          <rect height="16" fill="none" width="16" />
        </clipPath>
        <clipPath id="clip1_2215_12">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconError.defaultProps = {
  className: null,
  rootClassName: null,
};

IconError.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconError;
