import React, { forwardRef, useEffect, useRef } from 'react';

import css from './Trustpilot.module.css';

const TrustBox = forwardRef(({ theme, variant }, ref) => {
  switch (variant) {
    case 'carousel':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-review-languages="en"
          data-stars="4,5"
          data-style-height="140px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '140px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    case 'dropdown':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-review-languages="en"
          data-stars="4,5"
          data-style-height="30px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="5418052cfbfb950d88702476"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '30px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      );

    case 'grid':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-review-languages="en"
          data-stars="4,5"
          data-style-height="500px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="539adbd6dec7e10e686debee"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '500px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    case 'list':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-review-languages="en"
          data-stars="4,5"
          data-style-height="500px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="539ad60defb9600b94d7df2c"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '500px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      );

    case 'micro-button':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-height="24px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="5419b757fa0340045cd0c938"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '24px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://www.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    case 'micro-combo':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-height="20px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="5419b6ffb0d04a076446a9af"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '20px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    case 'micro-review-count':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-alignment="center"
          data-style-height="24px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '20px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    case 'micro-star':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-height="24px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="5419b732fbfb950b10de65e5"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '20px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    case 'micro-trust-score':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-height="20px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="5419b637fa0340045cd0c936"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '20px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    case 'mini':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-height="150px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '20px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      );

    case 'mini-carousel':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-review-languages="en"
          data-stars="4,5"
          data-style-height="350px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="539ad0ffdec7e10e686debd7"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '350px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      );

    case 'review-collector':
      return (
        <div
          className="trustpilot-widget"
          data-border-color="#f04f36"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-height="52px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="56278e9abfbbba0bdcd568bc"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '52px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      );

    case 'slider':
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-locale="en-GB"
          data-font-family="Roboto"
          data-review-languages="en"
          data-stars="4,5"
          data-style-height="240px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="54ad5defc6454f065c28af8b"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '240px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://uk.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );

    default:
      return (
        <div
          className="trustpilot-widget"
          data-businessunit-id="5f75d42bf2c23600015196da"
          data-font-family="Roboto"
          data-locale="en-GB"
          data-style-height="24px"
          data-style-width="100%"
          data-tags="MARKETPLACE"
          data-template-id="5419b732fbfb950b10de65e5"
          data-text-color={theme === 'light' ? '#4a4a4f' : '#ffffff'}
          data-theme={theme === 'light' ? 'light' : 'dark'}
          ref={ref}
          style={{
            borderStyle: 'none',
            display: 'block',
            height: '20px',
            left: '-8px',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
          }}
        >
          <a href="https://www.trustpilot.com/review/moose.co" rel="noopener" target="_blank">
            Trustpilot
          </a>
        </div>
      );
  }
});

const Trustpilot = props => {
  const { theme, variant } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className={css.trustpilotWidget}>
      <TrustBox ref={ref} theme={theme} variant={variant} />
    </div>
  );
};

export default Trustpilot;
