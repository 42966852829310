import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import css from './IconCalendar.module.css';

const IconCalendar = props => {
  const { className, colour = '#413c3c', rootClassName } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2219_31)">
        <path
          d="M1 2.5H15C15.2761 2.5 15.5 2.72386 15.5 3V15C15.5 15.2761 15.2761 15.5 15 15.5H1C0.723858 15.5 0.5 15.2761 0.5 15V3C0.5 2.72386 0.723858 2.5 1 2.5Z"
          fill="none"
          stroke={colour}
        />
        <path
          d="M3 3.25V1.75C3 1.19772 3.44772 0.75 4 0.75C4.55228 0.75 5 1.19772 5 1.75V3.25C5 3.80228 4.55228 4.25 4 4.25C3.44772 4.25 3 3.80228 3 3.25Z"
          fill="none"
          stroke={colour}
        />
        <path
          d="M11 3.25V1.75C11 1.19772 11.4477 0.75 12 0.75C12.5523 0.75 13 1.19772 13 1.75V3.25C13 3.80228 12.5523 4.25 12 4.25C11.4477 4.25 11 3.80228 11 3.25Z"
          fill="none"
          stroke={colour}
        />
        <path
          d="M3 8.9V7.1C3 7.04477 3.04477 7 3.1 7H4.9C4.95523 7 5 7.04477 5 7.1V8.9C5 8.95523 4.95523 9 4.9 9H3.1C3.04477 9 3 8.95523 3 8.9Z"
          fill="none"
          stroke={colour}
          strokeLinecap="round"
        />
        <path
          d="M3 12.9V11.1C3 11.0448 3.04477 11 3.1 11H4.9C4.95523 11 5 11.0448 5 11.1V12.9C5 12.9552 4.95523 13 4.9 13H3.1C3.04477 13 3 12.9552 3 12.9Z"
          fill="none"
          stroke={colour}
          strokeLinecap="round"
        />
        <path
          d="M7 8.9V7.1C7 7.04477 7.04477 7 7.1 7H8.9C8.95523 7 9 7.04477 9 7.1V8.9C9 8.95523 8.95523 9 8.9 9H7.1C7.04477 9 7 8.95523 7 8.9Z"
          fill="none"
          stroke={colour}
          strokeLinecap="round"
        />
        <path
          d="M7 12.9V11.1C7 11.0448 7.04477 11 7.1 11H8.9C8.95523 11 9 11.0448 9 11.1V12.9C9 12.9552 8.95523 13 8.9 13H7.1C7.04477 13 7 12.9552 7 12.9Z"
          fill="none"
          stroke={colour}
          strokeLinecap="round"
        />
        <path
          d="M11 8.9V7.1C11 7.04477 11.0448 7 11.1 7H12.9C12.9552 7 13 7.04477 13 7.1V8.9C13 8.95523 12.9552 9 12.9 9H11.1C11.0448 9 11 8.95523 11 8.9Z"
          fill="none"
          stroke={colour}
          strokeLinecap="round"
        />
        <path
          d="M11 12.9V11.1C11 11.0448 11.0448 11 11.1 11H12.9C12.9552 11 13 11.0448 13 11.1V12.9C13 12.9552 12.9552 13 12.9 13H11.1C11.0448 13 11 12.9552 11 12.9Z"
          fill="none"
          stroke={colour}
          strokeLinecap="round"
        />
        <path
          d="M0.5 4.9V2.6C0.5 2.54477 0.544772 2.5 0.600001 2.5H15.4C15.4552 2.5 15.5 2.54477 15.5 2.6V4.9C15.5 4.95523 15.4552 5 15.4 5H0.6C0.544772 5 0.5 4.95523 0.5 4.9Z"
          fill={colour}
        />
      </g>
    </svg>
  );
};

IconCalendar.defaultProps = {
  className: null,
  colour: '#413c3c',
  rootClassName: null,
};

IconCalendar.propTypes = {
  className: string,
  colour: string,
  rootClassName: string,
};

export default IconCalendar;
