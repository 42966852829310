export default [
  { key: 'black', label: 'Black' },
  { key: 'blue', label: 'Blue' },
  { key: 'brown', label: 'Brown' },
  { key: 'chrome', label: 'Chrome' },
  { key: 'green', label: 'Green' },
  { key: 'grey', label: 'Grey' },
  { key: 'darkGrey', label: 'Dark grey' },
  { key: 'lightGrey', label: 'Light grey' },
  { key: 'orange', label: 'Orange' },
  { key: 'pink', label: 'Pink' },
  { key: 'purple', label: 'Purple' },
  { key: 'red', label: 'Red' },
  { key: 'white', label: 'White' },
  { key: 'yellow', label: 'Yellow' },
];
