import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field from '../../containers/PageBuilder/Field';

import css from './Hero.module.css';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import Trustpilot from '../Trustpilot/Trustpilot';

const Hero = props => {
  const { appearance, callToActions, className, description, rootClassName, title } = props;

  const backgroundImage = appearance?.backgroundImage;

  return (
    <div className={classNames(rootClassName || css.root, className)}>
      {backgroundImage?.src ? (
        <div className={css.backgroundImageWrapper}>
          <img
            alt={backgroundImage.alt}
            height={backgroundImage.height}
            sizes={`${backgroundImage.width}px`}
            src={backgroundImage.src}
            width={backgroundImage.width}
          />

          <div
            className={css.backgroundImageOverlay}
            style={{
              backgroundColor: appearance.backgroundImageOverlay.color,
              opacity: appearance.backgroundImageOverlay.opacity,
            }}
          ></div>
        </div>
      ) : null}

      <div className={css.sectionContent}>
        <header className={css.sectionDetails}>
          <h1 className={css.title}>{title.content}</h1>
          <p className={css.description}>{description.content}</p>
          {callToActions.length ? (
            <div className={css.ctaContainer}>
              {callToActions.map(({ content, href }, index) => (
                <a className={css.ctaButton} href={href} key={index}>
                  {content}
                </a>
              ))}
            </div>
          ) : null}

          <div className={css.trustpilotContainer}>
            <Trustpilot theme="dark" variant="micro-combo" />
          </div>
        </header>
      </div>
    </div>
  );
};

Hero.defaultProps = {
  appearance: null,
  callToActions: [],
  className: null,
  defaultClasses: null,
  description: null,
  rootClassName: null,
  title: null,
};

Hero.propTypes = {
  appearance: shape({
    backgroundImage: shape({
      alt: string,
      loading: bool,
      height: number,
      src: string,
      width: number,
    }),
    backgroundImageOverlay: shape({
      color: string,
      opacity: number,
      preset: oneOf(['dark', 'light']),
    }),
    textColor: string,
  }),
  callToActions: arrayOf(
    shape({
      content: string,
      href: string,
    })
  ),
  className: string,
  defaultClasses: shape({
    ctaButton: string,
    description: string,
    sectionDetails: string,
    title: string,
  }),
  description: shape({
    content: string,
    fieldType: oneOf(['paragraph', 'span', 'small']),
  }),
  rootClassName: string,
  title: shape({
    content: string,
    fieldType: oneOf(['h1', 'h2']),
  }),
};

export default Hero;
