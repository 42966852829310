import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconSuccess.module.css';

const IconSuccess = props => {
  const { rootClassName, className, colour = '#413c3c' } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2216_25)">
        <path
          d="M4.30109 8.80713L6.12766 11.1031C6.23078 11.2327 6.43323 11.2304 6.53318 11.0985L11.2242 4.90624"
          fill="none"
          stroke={colour}
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          d="M0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8Z"
          fill="none"
          stroke={colour}
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

IconSuccess.defaultProps = {
  rootClassName: null,
  className: null,
  colour: null,
};

IconSuccess.propTypes = {
  rootClassName: string,
  className: string,
  colour: string,
};

export default IconSuccess;
