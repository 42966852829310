import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import css from './IconCircleTick.module.css';

const IconCircleTick = props => {
  const { className, colour = '#413c3c', rootClassName } = props;
  const classes = classNames(rootClassName || css.root, rootClassName);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6663 7.38723V8.00056C14.6655 9.43818 14.2 10.837 13.3392 11.9884C12.4785 13.1399 11.2685 13.9822 9.88991 14.3898C8.5113 14.7974 7.03785 14.7485 5.68932 14.2503C4.3408 13.7521 3.18944 12.8313 2.40698 11.6253C1.62452 10.4193 1.25287 8.9926 1.34746 7.5581C1.44205 6.1236 1.99781 4.75811 2.93186 3.66528C3.86591 2.57244 5.1282 1.81082 6.53047 1.49399C7.93274 1.17717 9.39985 1.32212 10.713 1.90723"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>

      <path
        d="M14.6667 2.66602L8 9.33935L6 7.33935"
        stroke={colour}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

IconCircleTick.defaultProps = {
  className: null,
  colour: '#413c3c',
  rootClassName: null,
};

IconCircleTick.propTypes = {
  className: string,
  colour: string,
  rootClassName: string,
};

export default IconCircleTick;
