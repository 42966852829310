import pick from 'lodash/pick';
import { initiatePrivileged, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //
export const COMPLETE_CHECKOUT_REQUEST = 'app/CheckoutPage/COMPLETE_CHECKOUT_REQUEST';
export const COMPLETE_CHECKOUT_SUCCESS = 'app/CheckoutPage/COMPLETE_CHECKOUT_SUCCESS';
export const COMPLETE_CHECKOUT_ERROR = 'app/CheckoutPage/COMPLETE_CHECKOUT_ERROR';

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_CHECKOUT_REQUEST = 'app/CheckoutPage/INITIATE_CHECKOUT_REQUEST';
export const INITIATE_CHECKOUT_SUCCESS = 'app/CheckoutPage/INITIATE_CHECKOUT_SUCCESS';
export const INITIATE_CHECKOUT_ERROR = 'app/CheckoutPage/INITIATE_CHECKOUT_ERROR';

export const SPECULATE_CHECKOUT_COMPLETE_ERROR =
  'app/CheckoutPage/SPECULATE_CHECKOUT_COMPLETE_ERROR';
export const SPECULATE_CHECKOUT_COMPLETE_REQUEST =
  'app/CheckoutPage/SPECULATE_CHECKOUT_COMPLETE_REQUEST';
export const SPECULATE_CHECKOUT_COMPLETE_SUCCESS =
  'app/CheckoutPage/SPECULATE_CHECKOUT_COMPLETE_SUCCESS';

export const SPECULATE_INITIATE_CHECKOUT_ERROR =
  'app/CheckoutPage/SPECULATE_INITIATE_CHECKOUT_ERROR';
export const SPECULATE_INITIATE_CHECKOUT_REQUEST =
  'app/CheckoutPage/SPECULATE_INITIATE_CHECKOUT_REQUEST';
export const SPECULATE_INITIATE_CHECKOUT_SUCCESS =
  'app/CheckoutPage/SPECULATE_INITIATE_CHECKOUT_SUCCESS';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const INITIATE_INQUIRY_REQUEST = 'app/CheckoutPage/INITIATE_INQUIRY_REQUEST';
export const INITIATE_INQUIRY_SUCCESS = 'app/CheckoutPage/INITIATE_INQUIRY_SUCCESS';
export const INITIATE_INQUIRY_ERROR = 'app/CheckoutPage/INITIATE_INQUIRY_ERROR';

// ================ Reducer ================ //
// Initial state
const initialState = {
  completeCheckoutError: null,
  completeCheckoutInProgress: false,
  initiateCheckoutError: null,
  initiateCheckoutInProgress: false,
  initiateInquiryError: null,
  initiateInquiryInProgress: false,
  listing: null,
  orderData: null,
  speculateCompleteCheckoutError: null,
  speculateCompleteCheckoutInProgress: true,
  speculateInitiateCheckoutError: null,
  speculateInitiateCheckoutInProgress: true,
  speculatedTransaction: null,
  speculateTransactionError: null,
  speculateTransactionInProgress: false,
  stripeCustomerFetched: false,
  transaction: null,
};

// Reducer function
export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case COMPLETE_CHECKOUT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        completeCheckoutError: payload,
        completeCheckoutInProgress: false,
      };
    case COMPLETE_CHECKOUT_REQUEST:
      return {
        ...state,
        completeCheckoutError: null,
        completeCheckoutInProgress: true,
      };
    case COMPLETE_CHECKOUT_SUCCESS:
      return {
        ...state,
        completeCheckoutError: null,
        completeCheckoutInProgress: false,
      };

    case INITIATE_CHECKOUT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateCheckoutError: payload };
    case INITIATE_CHECKOUT_REQUEST:
      return {
        ...state,
        initiateCheckoutError: null,
        initiateCheckoutInProgress: true,
      };
    case INITIATE_CHECKOUT_SUCCESS:
      return {
        ...state,
        initiateCheckoutInProgress: false,
        transaction: payload.transaction,
      };

    case INITIATE_INQUIRY_REQUEST:
      return { ...state, initiateInquiryInProgress: true, initiateInquiryError: null };
    case INITIATE_INQUIRY_SUCCESS:
      return { ...state, initiateInquiryInProgress: false };
    case INITIATE_INQUIRY_ERROR:
      return { ...state, initiateInquiryInProgress: false, initiateInquiryError: payload };

    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_CHECKOUT_COMPLETE_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateCompleteCheckoutInProgress: false,
        speculateCompleteCheckoutError: payload,
      };
    case SPECULATE_CHECKOUT_COMPLETE_REQUEST:
      return {
        ...state,
        speculateCompleteCheckoutError: null,
        speculateCompleteCheckoutInProgress: true,
      };
    case SPECULATE_CHECKOUT_COMPLETE_SUCCESS:
      return {
        ...state,
        speculateCompleteCheckoutInProgress: false,
        speculatedTransaction: payload.transaction,
      };

    case SPECULATE_INITIATE_CHECKOUT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateInitiateCheckoutInProgress: false,
        speculateInitiateCheckoutError: payload,
      };
    case SPECULATE_INITIATE_CHECKOUT_REQUEST:
      return {
        ...state,
        speculateInitiateCheckoutError: null,
        speculateInitiateCheckoutInProgress: true,
      };
    case SPECULATE_INITIATE_CHECKOUT_SUCCESS:
      return {
        ...state,
        speculateInitiateCheckoutInProgress: false,
        speculatedTransaction: payload.transaction,
      };

    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };
    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };

    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //
const completeCheckoutError = e => ({
  error: true,
  payload: e,
  type: COMPLETE_CHECKOUT_ERROR,
});

const completeCheckoutRequest = () => ({ type: COMPLETE_CHECKOUT_REQUEST });

const completeCheckoutSuccess = orderId => ({
  payload: orderId,
  type: COMPLETE_CHECKOUT_SUCCESS,
});

const initiateCheckoutError = e => ({
  type: INITIATE_CHECKOUT_ERROR,
  error: true,
  payload: e,
});

const initiateCheckoutRequest = () => ({ type: INITIATE_CHECKOUT_REQUEST });

const initiateCheckoutSuccess = transaction => ({
  type: INITIATE_CHECKOUT_SUCCESS,
  payload: { transaction },
});

export const initiateInquiryError = e => ({
  type: INITIATE_INQUIRY_ERROR,
  error: true,
  payload: e,
});
export const initiateInquiryRequest = () => ({ type: INITIATE_INQUIRY_REQUEST });
export const initiateInquirySuccess = () => ({ type: INITIATE_INQUIRY_SUCCESS });

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const speculateCheckoutCompleteError = e => ({
  error: true,
  payload: e,
  type: SPECULATE_CHECKOUT_COMPLETE_ERROR,
});

export const speculateCheckoutCompleteRequest = () => ({
  type: SPECULATE_CHECKOUT_COMPLETE_REQUEST,
});

export const speculateCheckoutCompleteSuccess = transaction => ({
  payload: { transaction },
  type: SPECULATE_CHECKOUT_COMPLETE_SUCCESS,
});

export const speculateInitiateCheckoutError = e => ({
  error: true,
  payload: e,
  type: SPECULATE_INITIATE_CHECKOUT_ERROR,
});

export const speculateInitiateCheckoutRequest = () => ({
  type: SPECULATE_INITIATE_CHECKOUT_REQUEST,
});

export const speculateInitiateCheckoutSuccess = transaction => ({
  payload: { transaction },
  type: SPECULATE_INITIATE_CHECKOUT_SUCCESS,
});

export const speculateInitialTransactionError = e => ({
  error: true,
  payload: e,
  type: SPECULATE_TRANSACTION_ERROR,
});

export const speculateInitialTransactionRequest = () => ({
  type: SPECULATE_TRANSACTION_REQUEST,
});

export const speculateInitialTransactionSuccess = transaction => ({
  payload: { transaction },
  type: SPECULATE_TRANSACTION_SUCCESS,
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});
export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });

/* ================ Thunks ================ */
export const completeCheckout = (orderParams, transactionId, transitionName) => (
  dispatch,
  _getState,
  _sdk
) => {
  dispatch(completeCheckoutRequest());

  const { deliveryMethod, quantity: stockReservationQuantity, ...otherOrderParams } = orderParams;

  const quantity = { stockReservationQuantity };
  const orderData = { deliveryMethod };
  const transitionParams = {
    ...quantity,
    ...otherOrderParams,
  };

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };

  const queryParams = { expand: true, include: ['provider'] };

  // Success functionality
  const handleSuccess = res => {
    const entities = denormalisedResponseEntities(res);
    const tx = entities[0];
    dispatch(completeCheckoutSuccess(tx));
  };

  // Error functionality
  const handleError = e => {
    log.error(e, 'complete-checkout-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantity,
      ...order,
    });

    return dispatch(completeCheckoutError(storableError(e)));
  };

  return transitionPrivileged({
    bodyParams,
    isSpeculative: false,
    orderData,
    queryParams,
  })
    .then(handleSuccess)
    .catch(handleError);
};

export const initiateCheckout = (orderParams, processAlias, transactionId, transitionName) => (
  dispatch,
  _getState,
  sdk
) => {
  dispatch(initiateCheckoutRequest());
  const { ...otherOrderParams } = orderParams;
  const transitionParams = { ...otherOrderParams };

  const isTransition = !!transactionId;
  const queryParams = { include: ['provider'], expand: true };
  const bodyParams = isTransition
    ? { id: transactionId, transition: transitionName, params: transitionParams }
    : { processAlias, transition: transitionName, params: transitionParams };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateCheckoutSuccess(order));
    // dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateCheckoutError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};

    log.error(e, 'initiate-checkout-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
    });
    throw e;
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(handleSuccess)
    .catch(handleError);
};

/**
 * Initiate transaction against default-inquiry process
 * Note: At this point inquiry transition is made directly against Marketplace API.
 *       So, client app's server is not involved here unlike with transitions including payments.
 *
 * @param {*} inquiryParams contains listingId and protectedData
 * @param {*} processAlias 'default-inquiry/release-1'
 * @param {*} transitionName 'transition/inquire-without-payment'
 * @returns
 */
export const initiateInquiryWithoutPayment = (inquiryParams, processAlias, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateInquiryRequest());

  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(initiateInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const bodyParams = {
    transition: transitionName,
    processAlias,
    params: inquiryParams,
  };
  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transactionId = response.data.data.id;
      dispatch(initiateInquirySuccess());
      return transactionId;
    })
    .catch(e => {
      dispatch(initiateInquiryError(storableError(e)));
      throw e;
    });
};

export const sendMessage = params => (_dispatch, _getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

export const speculateCompleteCheckout = (orderParams, transactionId, transitionName) => (
  dispatch,
  _getState,
  _sdk
) => {
  dispatch(speculateCheckoutCompleteRequest());

  const { deliveryMethod, quantity: stockReservationQuantity, ...otherOrderParams } = orderParams;

  const quantity = { stockReservationQuantity };
  const orderData = { deliveryMethod };
  const transitionParams = {
    ...quantity,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };

  const queryParams = { expand: true, include: ['provider'] };

  // Success functionality
  const handleSuccess = res => {
    const entities = denormalisedResponseEntities(res);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }

    const tx = entities[0];
    dispatch(speculateCheckoutCompleteSuccess(tx));
  };

  // Error functionality
  const handleError = e => {
    log.error(e, 'speculate-complete-checkout-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantity,
      ...orderData,
    });

    return dispatch(speculateCheckoutCompleteError(storableError(e)));
  };

  return transitionPrivileged({
    bodyParams,
    isSpeculative: true,
    orderData,
    queryParams,
  })
    .then(handleSuccess)
    .catch(handleError);
};

export const speculateInitiateCheckout = (
  orderParams,
  processAlias,
  transactionId,
  transitionName
) => (dispatch, _getState, sdk) => {
  dispatch(speculateInitiateCheckoutRequest());
  const { ...otherOrderParams } = orderParams;
  const transitionParams = { ...otherOrderParams };
  const isTransition = !!transactionId;
  const queryParams = { include: ['provider'], expand: true };
  const bodyParams = isTransition
    ? { id: transactionId, transition: transitionName, params: transitionParams }
    : { processAlias, transition: transitionName, params: transitionParams };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(speculateInitiateCheckoutSuccess(order));
    return order;
  };

  const handleError = e => {
    dispatch(speculateInitiateCheckoutError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};

    log.error(e, 'speculate-initiate-checkout-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
    });
    throw e;
  };

  if (isTransition) {
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }

  return sdk.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(handleSuccess)
    .catch(handleError);
};

export const speculateInitialTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, _getState, sdk) => {
  // Run state selector
  dispatch(speculateInitialTransactionRequest());

  // Determines whether this is an initiation or a transition.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity: stockReservationQuantity, ...otherOrderParams } = orderParams;

  const quantity = { stockReservationQuantity };
  const orderData = { deliveryMethod };
  const transitionParams = {
    ...quantity,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition: transitionName,
        params: transitionParams,
      }
    : {
        processAlias,
        transition: transitionName,
        params: transitionParams,
      };

  const queryParams = { expand: true, include: ['provider'] };

  // Success functionality
  const handleSuccess = res => {
    const entities = denormalisedResponseEntities(res);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }

    const tx = entities[0];
    dispatch(speculateInitialTransactionSuccess(tx));
  };

  // Error functionality
  const handleError = e => {
    log.error(e, 'speculate-initial-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantity,
      ...orderData,
    });

    return dispatch(speculateInitialTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    return transitionPrivileged({
      bodyParams,
      isSpeculative: true,
      orderData,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  }

  if (isTransition) {
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }

  if (isPrivilegedTransition) {
    return initiatePrivileged({
      bodyParams,
      isSpeculative: true,
      orderData,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  }

  return sdk.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(handleSuccess)
    .catch(handleError);
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod } : {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition: transitionName,
        params: transitionParams,
      }
    : {
        processAlias,
        transition: transitionName,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(
        {
          params: {
            cardToken: 'tok_mastercard',
            deliveryMethod: 'shipping',
            listingId: '661e4b92-8719-436d-9fc3-78d743a14258',
            protectedData: {
              deliveryMethod: 'shipping',
              shippingDetails: {
                address: {
                  city: 'Bristol',
                  country: 'GB',
                  line1: 'Flat 3 11 Sydenham Road',
                  line2: 'Cotham',
                  postal_code: 'BS65SH',
                },
                name: 'Ryan Dawkes',
              },
              unitType: 'item',
            },
            quantity: 1,
          },
          processAlias: 'default-purchase/release-1',
          transition: 'transition/confirm-payment',
        },
        { expand: true, include: ['provider'] }
      )
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
