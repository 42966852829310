import React from 'react';

import css from './IconRuler.module.css';
import classNames from 'classnames';

const IconRuler = props => {
  const { className, colour = '#413c3c', rootClassName } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.15486 4L5.84513 4C6.02513 4.00001 6.20581 4.04444 6.34722 4.15579C8.08267 5.52226 7.37683 9.34072 7.25693 9.92468C7.24754 9.97045 7.20775 10 7.16102 10H2.83898C2.79225 10 2.75246 9.97045 2.74307 9.92468C2.62317 9.34072 1.91733 5.52225 3.65278 4.15579C3.7942 4.04444 3.97487 4 4.15486 4Z"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
      />
      <path
        d="M6.125 15.375V10.625C6.125 10.556 6.18096 10.5 6.25 10.5C6.31904 10.5 6.375 10.556 6.375 10.625V15.375C6.375 15.444 6.31904 15.5 6.25 15.5C6.18096 15.5 6.125 15.444 6.125 15.375Z"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
      />

      <path
        d="M3.625 15.375V10.625C3.625 10.556 3.68096 10.5 3.75 10.5C3.81904 10.5 3.875 10.556 3.875 10.625V15.375C3.875 15.444 3.81904 15.5 3.75 15.5C3.68096 15.5 3.625 15.444 3.625 15.375Z"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
      />

      <path
        d="M5 3.5C4.17157 3.5 3.5 2.82843 3.5 2C3.5 1.17157 4.17157 0.5 5 0.5C5.82843 0.5 6.5 1.17157 6.5 2C6.5 2.82843 5.82843 3.5 5 3.5Z"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
      />

      <path
        d="M9.5 13.5L11.4293 15.4293C11.4683 15.4683 11.5317 15.4683 11.5707 15.4293L13.5 13.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
      />

      <path
        d="M9.5 2.5L11.4293 0.570711C11.4683 0.531658 11.5317 0.531658 11.5707 0.570711L13.5 2.5"
        fill="none"
        stroke={colour}
        strokeLinecap="round"
      />

      <path d="M11.5 1V14.5" fill="none" stroke={colour} strokeLinecap="round" />
    </svg>
  );
};

export default IconRuler;
