import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDelivery.module.css';

const IconDelivery = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      aria-hidden="true"
      className={classes}
      fill="none"
      focusable="false"
      height="44"
      role="presentation"
      viewBox="0 0 54 44"
      width="54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 22H18.7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M6.6001 38.5C6.6001 39.667 7.06367 40.7861 7.88883 41.6113C8.71399 42.4364 9.83314 42.9 11.0001 42.9C12.167 42.9 13.2862 42.4364 14.1114 41.6113C14.9365 40.7861 15.4001 39.667 15.4001 38.5C15.4001 37.333 14.9365 36.2139 14.1114 35.3887C13.2862 34.5636 12.167 34.1 11.0001 34.1C9.83314 34.1 8.71399 34.5636 7.88883 35.3887C7.06367 36.2139 6.6001 37.333 6.6001 38.5V38.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M37.3999 38.5C37.3999 39.667 37.8635 40.7861 38.6886 41.6113C39.5138 42.4364 40.6329 42.9 41.7999 42.9C42.9669 42.9 44.086 42.4364 44.9112 41.6113C45.7363 40.7861 46.1999 39.667 46.1999 38.5C46.1999 37.333 45.7363 36.2139 44.9112 35.3887C44.086 34.5636 42.9669 34.1 41.7999 34.1C40.6329 34.1 39.5138 34.5636 38.6886 35.3887C37.8635 36.2139 37.3999 37.333 37.3999 38.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M6.6001 38.5H2.2001C1.90836 38.5 1.62857 38.3841 1.42228 38.1778C1.21599 37.9715 1.1001 37.6917 1.1001 37.4V26.8554C1.10016 26.5642 1.21567 26.285 1.4213 26.0788L5.5001 22L10.3709 13.2308C10.5615 12.888 10.8403 12.6025 11.1784 12.4036C11.5164 12.2048 11.9015 12.1 12.2937 12.1H18.7001V2.20001C18.7001 1.90827 18.816 1.62848 19.0223 1.42219C19.2286 1.2159 19.5084 1.10001 19.8001 1.10001H50.6001C50.8918 1.10001 51.1716 1.2159 51.3779 1.42219C51.5842 1.62848 51.7001 1.90827 51.7001 2.20001V37.4C51.7001 37.6917 51.5842 37.9715 51.3779 38.1778C51.1716 38.3841 50.8918 38.5 50.6001 38.5H46.2001"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M15.3999 38.5H37.3999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M18.7002 12.1V34.1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
      <path
        d="M1.1001 34.1H51.7001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconDelivery.defaultProps = {
  className: null,
  rootClassName: null,
};

IconDelivery.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDelivery;
