import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconMail.module.css';

const IconMail = props => {
  const { className, colour = '#413c3c', rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M0.75 12.75V3.75H15.25V12.75H0.75Z" stroke={colour} strokeWidth="1.5" />

        <path
          d="M1 4L7.72962 8.32619C7.89433 8.43207 8.10567 8.43207 8.27038 8.32619L15 4"
          stroke={colour}
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

IconMail.defaultProps = {
  className: null,
  colour: '#413c3c',
  rootClassName: null,
};

IconMail.propTypes = {
  className: string,
  colour: string,
  rootClassName: string,
};

export default IconMail;
