import React from 'react';

import css from './ListingCardCallouts.module.css';
import { IconCalendar, IconDistance, IconRuler, IconInfo } from '..';

const CalloutItem = props => {
  const {
    callout: [key, value],
  } = props;

  const Icon = () => {
    switch (key) {
      case 'distance':
        return <IconDistance />;

      case 'frameSize':
        return <IconRuler />;

      case 'year':
        return <IconCalendar />;

      default:
        return <IconInfo />;
    }
  };

  if (!value) {
    return null;
  }

  const valueMarkdown = () => {
    if ((key === 'frameSize') & !Number.isNaN(Number(value))) {
      return `${value}cm`;
    } else if (key === 'category' && Array.isArray(value)) {
      const categoryValues = value.map(nestedValue =>
        nestedValue
          .replaceAll('full-suspension', '')
          .replaceAll('hardtail', '')
          .trim()
      );

      return Array.from(new Set(categoryValues))
        .join(', ')
        .replaceAll('-', '');
    } else {
      return value;
    }
  };

  return (
    <li className={css.calloutItemContainer}>
      <Icon />
      <span className={css.calloutValue}>{valueMarkdown()}</span>
    </li>
  );
};

const ListingCardCallouts = props => {
  const { callouts } = props;

  return (
    <ul className={css.calloutsContainer}>
      {Object.entries(callouts).map((callout, index) => (
        <CalloutItem callout={callout} key={index} />
      ))}
    </ul>
  );
};

export default ListingCardCallouts;
