import React from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  IconSuccess,
  ListingCardCallouts,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';

const moneyFormatter = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' });

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const { className, rootClassName, listing, renderSizes, setActiveListing } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { price, publicData, title = '' } = currentListing.attributes;

  const { about, make, rrp, specs } = publicData;

  const { metadata = {} } = currentListing.author?.attributes?.profile;

  const callouts = {
    category: publicData?.type || specs?.type || undefined,
    distance: specs?.distance || undefined,
    frameSize: publicData?.frameSize || specs?.frameSize || undefined,
    year: about?.year || undefined,
  };

  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  // const isHotDeal = specs?.rrp ? price.amount / 100 / specs?.rrp <= 0.5 : false;
  // const isVerifiedListing = specs?.rrp ? price.amount / 100 / specs?.rrp <= 0.5 : false;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      {/* Focus image */}
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        height={aspectHeight}
        width={aspectWidth}
        {...setActivePropsMaybe}
      >
        <LazyImage
          alt={title}
          image={firstImage}
          rootClassName={css.rootForImage}
          sizes={renderSizes}
          variants={variants}
        />

        {currentListing?.attributes?.publicData?.listingType ? (
          <div className={css.productType}>{currentListing.attributes.publicData.listingType}</div>
        ) : null}
      </AspectRatioWrapper>

      {/* Info */}
      <div className={css.info}>
        {/* Callouts */}
        <ListingCardCallouts callouts={callouts} />

        {/* Info */}
        <div className={css.infoContainer}>
          {/* Title */}
          <div className={css.mainInfo}>
            <div className={css.title}>{about?.model || title}</div>
            {make || about?.make || about?.customMake ? (
              <div className={css.make}>{make || about.make || about.customMake}</div>
            ) : null}
          </div>

          {/* Price */}
          <div className={css.priceContainer}>
            {rrp || specs?.rrp ? (
              <s className={css.rrp}>
                {new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                }).format(rrp || specs?.rrp)}
              </s>
            ) : null}

            <div className={css.salePrice}>{moneyFormatter.format(price.amount / 100)}</div>
          </div>
        </div>

        {/* Tags */}
        {/* <div className={css.tags}>
          {isHotDeal ? <span className={css.tagHotDeal}>🔥 Hot deal</span> : null}

          {isVerifiedListing ? <span className={css.tagVerified}>✅ Verified bike</span> : null}
        </div> */}
      </div>

      {/* Verified user tag */}
      {metadata?.verified ? (
        <div className={css.verifiedUser}>
          <div className={css.verifiedUserText}>
            <FormattedMessage id={'ListingCard.verifiedSeller'} />
          </div>

          <div className={css.verifiedUserIcon}>
            <IconSuccess colour="#ffffff" />
          </div>
        </div>
      ) : null}
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
