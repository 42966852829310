import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconChevron.module.css';

const IconChevron = props => {
  const { className, colour = '#413c3c', rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 14 14"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="m11.9 5.6-4.653 4.653a.35.35 0 0 1-.495 0L2.1 5.6"
          stroke={colour}
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  );
};

IconChevron.defaultProps = {
  className: null,
  colour: '#413c3c',
  rootClassName: null,
};

IconChevron.propTypes = {
  className: string,
  colour: string,
  rootClassName: string,
};

export default IconChevron;
