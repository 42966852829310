import React, { useState } from 'react';

import css from './UserProcessSteps.module.css';
import { buyingImage, sellingImage } from '../../config/configBranding';
import Trustpilot from '../Trustpilot/Trustpilot';
import { object, oneOf } from 'prop-types';
import classNames from 'classnames';

const UserProcessSteps = props => {
  const { intl, userType } = props;
  const [selectedTab, setSelectedTab] = useState(userType || 'sell');

  return (
    <div className={css.container}>
      {/* Navigation */}
      {userType !== 'sell' && userType !== 'buy' ? (
        <nav className={css.tabsContainer}>
          {/* Selling */}
          <div className={css.tab}>
            <button
              className={classNames(
                css.tabContent,
                selectedTab === 'sell' ? css.tabContentSelected : null
              )}
              onClick={() => setSelectedTab('sell')}
              type="button"
            >
              Selling
            </button>
          </div>

          {/* Buying */}
          <div className={css.tab}>
            <button
              className={classNames(
                css.tabContent,
                selectedTab === 'buy' ? css.tabContentSelected : null
              )}
              onClick={() => setSelectedTab('buy')}
              type="button"
            >
              Buying
            </button>
          </div>
        </nav>
      ) : null}

      <div className={css.heading}>
        <h3>
          {selectedTab === 'sell' || userType === 'sell'
            ? intl.formatMessage({ id: 'SignupPage.sellHeading' })
            : intl.formatMessage({ id: 'SignupPage.buyHeading' })}
        </h3>

        <p>
          {selectedTab === 'sell' || userType === 'sell'
            ? intl.formatMessage({ id: 'SignupPage.sellTagline' })
            : intl.formatMessage({ id: 'SignupPage.buyTagline' })}
        </p>

        <div className={css.trustpilotContainer}>
          <Trustpilot theme="light" variant="micro-combo" />
        </div>
      </div>

      {/* Content */}
      <div className={css.contentContainer}>
        <div className={css.content}>
          <div className={css.contentWithImage}>
            {selectedTab === 'sell' || userType === 'sell' ? (
              <img
                alt="List your bike"
                height="1621"
                loading="eager"
                src={sellingImage}
                width="1419"
              />
            ) : (
              <img
                alt="Buy your dream bike"
                height="1621"
                loading="eager"
                src={buyingImage}
                width="1419"
              />
            )}

            <ul className={css.contentWithImageText}>
              <li className={css.contentWithImageBlock}>
                {selectedTab === 'sell' || userType === 'sell' ? (
                  <>
                    <h4>{intl.formatMessage({ id: 'SignupForm.sellStepOneTitle' })}</h4>
                    <p>{intl.formatMessage({ id: 'SignupForm.sellStepOneInfo' })}</p>
                  </>
                ) : (
                  <>
                    <h4>{intl.formatMessage({ id: 'SignupForm.buyStepOneTitle' })}</h4>
                    <p>{intl.formatMessage({ id: 'SignupForm.buyStepOneInfo' })}</p>
                  </>
                )}
              </li>

              <li className={css.contentWithImageBlock}>
                {selectedTab === 'sell' || userType === 'sell' ? (
                  <>
                    <h4>{intl.formatMessage({ id: 'SignupForm.sellStepTwoTitle' })}</h4>
                    <p>{intl.formatMessage({ id: 'SignupForm.sellStepTwoInfo' })}</p>
                  </>
                ) : (
                  <>
                    <h4>{intl.formatMessage({ id: 'SignupForm.buyStepTwoTitle' })}</h4>
                    <p>{intl.formatMessage({ id: 'SignupForm.buyStepTwoInfo' })}</p>
                  </>
                )}
              </li>

              <li className={css.contentWithImageBlock}>
                {selectedTab === 'sell' || userType === 'sell' ? (
                  <>
                    <h4>{intl.formatMessage({ id: 'SignupForm.sellStepThreeTitle' })}</h4>
                    <p>{intl.formatMessage({ id: 'SignupForm.sellStepThreeInfo' })}</p>
                  </>
                ) : (
                  <>
                    <h4>{intl.formatMessage({ id: 'SignupForm.buyStepThreeTitle' })}</h4>
                    <p>{intl.formatMessage({ id: 'SignupForm.buyStepThreeInfo' })}</p>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

UserProcessSteps.defaultProps = {};

UserProcessSteps.propTypes = {
  intl: object,
  userType: oneOf(['buy', 'sell', null]),
};

export default UserProcessSteps;
