import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconShield.module.css';

const IconShield = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_2138_7)">
        <path
          d="M1.80242 2.31971C3.01945 2.7094 5.42604 3.11207 7.64832 1.30618C7.85182 1.14082 8.14823 1.14082 8.35172 1.30618C10.574 3.11207 12.9806 2.70941 14.1976 2.31971C14.5639 2.20242 14.9725 2.47506 14.9327 2.8576C14.6775 5.31235 13.4929 12.3531 8.20606 14.9038C8.07685 14.9662 7.9232 14.9662 7.79399 14.9038C2.50712 12.3531 1.32256 5.31235 1.06731 2.8576C1.02753 2.47506 1.43613 2.20242 1.80242 2.31971Z"
          stroke="#413C3C"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          stroke="#413C3C"
          strokeLinecap="round"
          strokeWidth="1.5"
          transform="matrix(0.729537 0.683942 -0.729537 0.683942 4.5 8.49994)"
          x1="0.75"
          x2="2.90528"
          y1="-0.75"
          y2="-0.75"
        />
        <line
          stroke="#413C3C"
          strokeLinecap="round"
          strokeWidth="1.5"
          transform="matrix(0.729537 -0.683942 0.729537 0.683942 7.16669 11)"
          x1="0.75"
          x2="6.56056"
          y1="-0.75"
          y2="-0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_2138_7">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconShield.defaultProps = {
  className: null,
  rootClassName: null,
};

IconShield.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconShield;
