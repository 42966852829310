import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLock.module.css';

const IconLock = props => {
  const { className, colour = '#413c3c', rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="44"
      role="presentation"
      viewBox="0 0 33 44"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path
          d="M14.6665 27.5C14.6665 27.9862 14.8597 28.4526 15.2035 28.7964C15.5473 29.1402 16.0136 29.3333 16.4998 29.3333C16.9861 29.3333 17.4524 29.1402 17.7962 28.7964C18.14 28.4526 18.3332 27.9862 18.3332 27.5C18.3332 27.0138 18.14 26.5475 17.7962 26.2036C17.4524 25.8598 16.9861 25.6667 16.4998 25.6667C16.0136 25.6667 15.5473 25.8598 15.2035 26.2036C14.8597 26.5475 14.6665 27.0138 14.6665 27.5Z"
          stroke={colour}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>

        <path
          d="M16.5 29.3333V34.8333"
          stroke={colour}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>

        <path
          d="M0.916504 17.4167H32.0832V43.0833H0.916504V17.4167Z"
          stroke={colour}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>

        <path
          d="M6.4165 11C6.4165 8.32574 7.47885 5.761 9.36984 3.87001C11.2608 1.97902 13.8256 0.916672 16.4998 0.916672C19.1741 0.916672 21.7388 1.97902 23.6298 3.87001C25.5208 5.761 26.5832 8.32574 26.5832 11V17.4167H6.4165V11Z"
          stroke={colour}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
};

IconLock.defaultProps = {
  className: null,
  colour: '#413c3c',
  rootClassName: null,
};

IconLock.propTypes = {
  className: string,
  colour: string,
  rootClassName: string,
};

export default IconLock;
