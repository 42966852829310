import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconQuestion.module.css';

const IconQuestion = props => {
  const { className, colour = '#f04f36', rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        className={css.marketplaceColorStroke}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M5.09166 4.94305C4.63712 2.81818 5.90404 1 8.20578 1C10.2046 1 11.4553 2.44578 11.4553 4.28587C11.4553 5.57369 9.92074 6.77388 8.84166 7.6718C8.42601 8.01767 8.20578 8.53406 8.20578 9.07479V11.9091"
          stroke={colour}
          strokeLinecap="round"
          strokeWidth="2"
        />

        <path
          d="M7.13712 14.8636C7.13712 14.236 7.64588 13.7273 8.27348 13.7273C8.90108 13.7273 9.40984 14.236 9.40984 14.8636C9.40984 15.4912 8.90108 16 8.27348 16C7.64588 16 7.13712 15.4912 7.13712 14.8636Z"
          fill={colour}
          stroke="none"
        />
      </g>
    </svg>
  );
};

IconQuestion.defaultProps = {
  className: null,
  rootClassName: null,
};

IconQuestion.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconQuestion;
