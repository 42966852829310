import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { displayDeliveryShipping, displayPrice } from '../../util/configHelpers';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_ITEM } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { formatMoneyFromString } from '../../util/money';
import { parse, stringify } from '../../util/urlHelpers';
import {
  INQUIRY_PROCESS_NAME,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

import {
  H1,
  H2,
  IconMail,
  ModalInMobile,
  PrimaryButton,
  SecondaryButton,
  Trustpilot,
} from '../../components';

import css from './OrderPanel.module.css';

const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = async (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  if (isInquiryWithoutPayment) {
    onSubmit({});
  } else {
    openOrderModal(isOwnListing, isClosed, history, location);
  }
};

const PriceMaybe = props => {
  const {
    intl,
    marketplaceCurrency,
    price,
    publicData,
    showCurrencyMismatch = false,
    validListingTypes,
  } = props;

  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    currentListing,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    onManageDisableScrolling,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
  } = props;

  const publicData = listing?.attributes?.publicData || {};
  const { about, listingType, specs, transactionProcessAlias = '', unitType } = publicData || {};

  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;

  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const { shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  if (!about || !specs) {
    return <p>Loading...</p>;
  }

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        onManageDisableScrolling={onManageDisableScrolling}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        usePortal
      >
        <div className={css.modalContentBlock}>
          {/* Trustpilot */}
          <div className={classNames(css.modalContentBlock, css.trustpilotContentBlock)}>
            <Trustpilot theme="light" variant="horizontal" />
          </div>

          {/* Heading */}
          <div className={css.modalHeading}>
            <H1 className={css.heading}>{title}</H1>
          </div>

          {/* Subheading */}
          <div className={css.orderHeading}>
            {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
            {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
          </div>

          {/* Price */}
          <div className={css.orderPriceContainer}>
            <div className={css.orderPrice}>
              {specs ? <s>{formatMoneyFromString(specs.rrp)}</s> : null}

              <div className={css.orderPriceProvisional}>
                <PriceMaybe
                  price={price}
                  publicData={publicData}
                  validListingTypes={validListingTypes}
                  intl={intl}
                  marketplaceCurrency={marketplaceCurrency}
                />
                <small>+ fees</small>
              </div>
            </div>

            {price.amount / 100 < Number(specs.rrp) ? (
              <div className={css.orderPriceDiscount}>
                <p>{Math.round((1 - price.amount / 100 / Number(specs.rrp)) * 100)}% drop</p>
              </div>
            ) : null}
          </div>
        </div>

        <div className={css.modalContentBlock}>
          <ProductOrderForm
            allowOrdersOfMultipleItems={false}
            currentListing={currentListing}
            currentStock={currentStock}
            displayDeliveryMethod={displayShipping}
            fetchLineItemsError={fetchLineItemsError}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            formId="OrderPanelProductOrderForm"
            isOwnListing={isOwnListing}
            lineItems={lineItems}
            listingId={listing.id}
            marketplaceCurrency={marketplaceCurrency}
            marketplaceName={marketplaceName}
            onContactUser={onContactUser}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onSubmit={onSubmit}
            payoutDetailsWarning={payoutDetailsWarning}
            pickupEnabled={false}
            price={price}
            shippingEnabled={shippingEnabled}
          />
        </div>
      </ModalInMobile>

      <div className={css.openOrderForm}>
        <PriceMaybe
          price={price}
          publicData={publicData}
          validListingTypes={validListingTypes}
          intl={intl}
          marketplaceCurrency={marketplaceCurrency}
          showCurrencyMismatch
        />

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <div className={css.actionsContainer}>
            <PrimaryButton
              disabled={isOutOfStock}
              onClick={() =>
                handleSubmit(isOwnListing, isClosed, showInquiryForm, onSubmit, history, location)
              }
              type="button"
            >
              {isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
              )}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  authorLink: null,
  className: null,
  fetchLineItemsError: null,
  isOwnListing: false,
  lineItems: null,
  payoutDetailsWarning: null,
  rootClassName: null,
  subTitle: null,
  titleClassName: null,
  titleDesktop: null,
};

OrderPanel.propTypes = {
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  className: string,
  fetchLineItemsError: propTypes.error,
  fetchLineItemsInProgress: bool.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  isOwnListing: bool,
  lineItems: array,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  location: shape({
    search: string,
  }).isRequired,
  marketplaceCurrency: string.isRequired,
  marketplaceName: string.isRequired,
  monthlyTimeSlots: object,
  onContactUser: func,
  onFetchTransactionLineItems: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSubmit: func.isRequired,
  payoutDetailsWarning: node,
  rootClassName: string,
  subTitle: oneOfType([node, string]),
  title: oneOfType([node, string]).isRequired,
  titleClassName: string,
  titleDesktop: node,
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
