import React, { useCallback, useEffect, useState } from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import classNames from 'classnames';

import { IconError, IconInfo, IconSuccess } from '..';

import css from './Toast.module.css';

const ToastIcon = props => {
  const { icon } = props;

  switch (icon) {
    case 'error':
      return <IconError colour="#ff0000" />;

    case 'success':
      return <IconSuccess colour="#2ecc71" />;

    case 'warning':
      return <IconError colour="#ffaa00" />;

    default:
      return <IconInfo colour="#f04f36" />;
  }
};

const Toast = props => {
  const {
    active = false,
    content,
    status,
    title,
    setActive,
    setContent,
    setStatus,
    setTitle,
  } = props;

  const handleClickToClose = useCallback(() => {
    if (active) {
      setActive(false);
      new Promise(resolve => setTimeout(() => resolve(true), 500)).then(() => {
        setContent(null);
        setStatus('information');
        setTitle(null);
      });
    }
  }, [active]);

  // Trigger inactive state after activating
  useEffect(() => {
    if (active) {
      new Promise(resolve => setTimeout(() => resolve(true), 3000)).then(() => {
        // Hide component first...
        setActive(false);

        // ...then reset all content (debounced)
        new Promise(resolve => setTimeout(() => resolve(true), 500)).then(() => {
          setContent(null);
          setStatus('information');
          setTitle(null);
        });
      });
    }
  }, [active]);

  return (
    <div
      className={classNames(
        css.toastContainer,
        active ? css.toastActive : css.toastInactive,
        status === 'error'
          ? css.toastError
          : status === 'success'
          ? css.toastSuccess
          : status === 'warning'
          ? css.toastWarning
          : css.toastInfo
      )}
      onClick={handleClickToClose}
    >
      <div className={css.toastIconContainer}>
        <ToastIcon icon={status} />
      </div>

      <div className={css.toastContent}>
        <h5 className={css.toastTitle}>{title}</h5>
        {content ? <p className={css.toastText}>{content}</p> : null}
      </div>
    </div>
  );
};

Toast.defaultProps = {
  active: false,
  content: '',
  status: 'information',
  title: 'Action occurred',
};

Toast.propTypes = {
  active: bool,
  content: string,
  status: oneOf(['error', 'information', 'success', 'warning']),
  title: string,
  setActive: func,
  setContent: func,
  setStatus: func,
  setTitle: func,
};

export default Toast;
