export default [
  { key: 'XS', label: 'XS' },
  { key: 'S', label: 'S' },
  { key: 'M', label: 'M' },
  { key: 'L', label: 'L' },
  { key: 'XL', label: 'XL' },
  { key: '42', label: '42' },
  { key: '43', label: '43' },
  { key: '44', label: '44' },
  { key: '45', label: '45' },
  { key: '46', label: '46' },
  { key: '47', label: '47' },
  { key: '48', label: '48' },
  { key: '49', label: '49' },
  { key: '50', label: '50' },
  { key: '51', label: '51' },
  { key: '52', label: '52' },
  { key: '53', label: '53' },
  { key: '54', label: '54' },
  { key: '55', label: '55' },
  { key: '56', label: '56' },
  { key: '57', label: '57' },
  { key: '58', label: '58' },
  { key: '59', label: '59' },
  { key: '60', label: '60' },
  { key: '61', label: '61' },
  { key: '62', label: '62' },
  { key: '63', label: '63' },
  { key: '64', label: '64' },
];
