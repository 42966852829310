import pick from 'lodash/pick';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import {
  getCheckoutSession,
  getPaymentMethodId,
  initiatePrivileged,
  transitionPrivileged,
} from '../../util/api';

export const FETCH_STRIPE_SESSION_ERROR = 'app/OrderProcessingPage/FETCH_STRIPE_SESSION_ERROR';
export const FETCH_STRIPE_SESSION_REQUEST = 'app/OrderProcessingPage/FETCH_STRIPE_SESSION_REQUEST';
export const FETCH_STRIPE_SESSION_SUCCESS = 'app/OrderProcessingPage/FETCH_STRIPE_SESSION_SUCCESS';

export const INITIATE_INQUIRY_REQUEST = 'app/OrderProcessingPage/INITIATE_INQUIRY_REQUEST';
export const INITIATE_INQUIRY_SUCCESS = 'app/OrderProcessingPage/INITIATE_INQUIRY_SUCCESS';
export const INITIATE_INQUIRY_ERROR = 'app/OrderProcessingPage/INITIATE_INQUIRY_ERROR';

export const INITIATE_ORDER_REQUEST = 'app/OrderProcessingPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/OrderProcessingPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/OrderProcessingPage/INITIATE_ORDER_ERROR';

export const SET_INITIAL_VALUES = 'app/OrderProcessingPage/SET_INITIAL_VALUES';

export const SPECULATE_STRIPE_PAYMENT_REQUEST =
  'app/OrderProcessingPage/SPECULATE_STRIPE_PAYMENT_REQUEST';
export const SPECULATE_STRIPE_PAYMENT_ERROR =
  'app/OrderProcessingPage/SPECULATE_STRIPE_PAYMENT_ERROR';
export const SPECULATE_STRIPE_PAYMENT_SUCCESS =
  'app/OrderProcessingPage/SPECULATE_STRIPE_PAYMENT_SUCCESS';

const FETCH_STRIPE_ID_REQUEST = 'app/OrderProcessingPage/FETCH_STRIPE_ID_REQUEST';

/** ================ Reducer ================ */
const initialState = {
  fetchStripeSessionError: null,
  fetchStripeSessionInProgress: false,
  initiateInquiryError: null,
  initiateInquiryInProgress: false,
  initiateOrderError: null,
  listing: null,
  orderData: null,
  paymentData: null,
  speculatedStripePayment: null,
  speculateStripePaymentError: null,
  speculateStripePaymentInProgress: false,
  stripeSessionData: null,
  transaction: null,
};

export default function orderProcessingPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_STRIPE_SESSION_ERROR:
      return {
        ...state,
        fetchStripeSessionError: payload,
        fetchStripeSessionInProgress: false,
      };

    case FETCH_STRIPE_SESSION_REQUEST:
      return {
        ...state,
        fetchStripeSessionError: null,
        fetchStripeSessionInProgress: true,
      };

    case FETCH_STRIPE_SESSION_SUCCESS:
      return {
        ...state,
        fetchStripeSessionInProgress: false,
        stripeSessionData: payload,
      };

    case INITIATE_INQUIRY_ERROR:
      return {
        ...state,
        initiateInquiryError: payload,
        initiateInquiryInProgress: false,
      };

    case INITIATE_INQUIRY_REQUEST:
      return {
        ...state,
        initiateInquiryError: null,
        initiateInquiryInProgress: true,
      };

    case INITIATE_INQUIRY_SUCCESS:
      return { ...state, initiateInquiryInProgress: false };

    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };

    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };

    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        speculateStripePaymentError: null,
        speculateStripePaymentInProgress: true,
        speculatedStripePayment: null,
      };

    case SPECULATE_STRIPE_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateStripePaymentError: payload,
        speculateStripePaymentInProgress: false,
      };

    case SPECULATE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        speculateStripePaymentInProgress: false,
        speculatedStripePayment: payload.transaction,
      };

    default:
      return state;
  }
}

/** ================ Action creators ================ */
export const setInitialValues = initialValues => ({
  payload: pick(initialValues, Object.keys(initialState)),
  type: SET_INITIAL_VALUES,
});

export const fetchStripeSessionError = e => ({
  error: true,
  payload: e,
  type: FETCH_STRIPE_SESSION_ERROR,
});

export const fetchStripeSessionRequest = () => ({
  type: FETCH_STRIPE_SESSION_REQUEST,
});

export const fetchStripeSessionSuccess = session => ({
  payload: session,
  type: FETCH_STRIPE_SESSION_SUCCESS,
});

export const initiateInquiryError = e => ({
  error: true,
  payload: e,
  type: INITIATE_INQUIRY_ERROR,
});

export const initiateInquiryRequest = () => ({
  type: INITIATE_INQUIRY_REQUEST,
});

export const initiateInquirySuccess = () => ({
  type: INITIATE_INQUIRY_SUCCESS,
});

const initiateOrderError = e => ({
  error: true,
  payload: e,
  type: INITIATE_ORDER_ERROR,
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  payload: order,
  type: INITIATE_ORDER_SUCCESS,
});

export const speculateStripePaymentError = e => ({
  error: true,
  payload: e,
  type: SPECULATE_STRIPE_PAYMENT_ERROR,
});

export const speculateStripePaymentRequest = () => ({
  type: SPECULATE_STRIPE_PAYMENT_REQUEST,
});

export const speculateStripePaymentSuccess = transaction => ({
  payload: { transaction },
  type: SPECULATE_STRIPE_PAYMENT_SUCCESS,
});

/** ================ Thunks ================ */
export const fetchStripeCheckoutSession = sessionId => async (dispatch, getState, sdk) => {
  dispatch(fetchStripeSessionRequest());

  try {
    const checkoutSession = await getCheckoutSession(sessionId);

    if (!checkoutSession || !checkoutSession.payment_intent || !checkoutSession.customer) {
      throw new Error('Failed to retrieve Checkout Session');
    }

    const paymentMethodId = await getPaymentMethodId(checkoutSession.payment_intent);

    if (!paymentMethodId) {
      throw new Error('Failed to retrieve Payment Method ID from Stripe');
    }

    const {
      client_reference_id: listingId,
      customer_details: { address, email, name, phone },
      payment_intent: paymentIntentId,
      payment_status: paymentStatus,
      shipping_details: { address: shippingAddress, name: shippingName },
      status,
    } = checkoutSession;
    dispatch(fetchStripeSessionSuccess(checkoutSession));
    dispatch(
      initiateOrder(
        {
          listingId,
          deliveryMethod: 'shipping',
          quantity: 1,
          protectedData: {
            unitType: 'item',
            deliveryMethod: 'shipping',
            shippingDetails: {
              name: shippingName,
              address: shippingAddress,
            },
          },
          paymentMethod: paymentMethodId,
        },
        'default-purchase/release-1',
        null,
        'transition/confirm-payment',
        true
      )
    );
  } catch (e) {
    return dispatch(fetchStripeSessionError(e));
  }
};

/**
 * @desc
 * Initiates an order between the buyer and the seller for a given listing.
 * @param {*} orderParams The params for the given order.
 * @param {*} processAlias
 * The name of the transaction process alias in this transaction.
 * @param {*} transactionId The ID of this transaction.
 * @param {*} transitionName
 * The name of the transition to use in the transaction process.
 * @param {*} isPrivilegedTransition
 * Whether this is a privileged transition (i.e. requires auth) or not.
 * @returns
 */
export const initiateOrder = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, _getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If `transactionId` exists, do not initiate - transition instead.
  const isTransition = !!transactionId;
  const { deliveryMethod, quantity, ...otherOrderParams } = orderParams;

  const quantityMaybe = quantity
    ? { stockReservationQuantity: quantity }
    : { stockReservationQuantity: 1 };

  // Params only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod } : { deliveryMethod: 'shipping' };

  // Params for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        params: transitionParams,
        transition: transitionName,
      }
    : {
        params: transitionParams,
        processAlias,
        transition: transitionName,
      };

  const queryParams = {
    expand: true,
    include: ['provider'],
  };

  // Error response
  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};

    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...orderData,
    });

    throw e;
  };

  // Success response
  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  if (isTransition && isPrivilegedTransition) {
    return transitionPrivileged({
      bodyParams,
      isSpeculative: false,
      orderData,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    return initiatePrivileged({
      bodyParams,
      isSpeculative: false,
      orderData,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

/**
 * @desc Sends a message from the buyer to the seller confirming the order.
 */
export const sendMessage = params => (_dispatch, _getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ content: message, transactionId: orderId })
      .then(() => {
        return { messageSuccess: true, orderId };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });

        return { messageSuccess: false, orderId };
      });
  } else {
    return Promise.resolve({ messageSuccess: true, orderId });
  }
};

export const speculateStripePayment = (
  orderParams,
  processAlias,
  transactionId,
  isPrivilegedTransition
) => (dispatch, _getState, sdk) => {
  dispatch(speculateStripePaymentRequest());

  // If a transaction ID exists, transition instead of initiating.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};

  // Params for client app's server.
  const orderData = deliveryMethod ? { deliveryMethod } : {};

  // Params for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...otherOrderParams,
    cardToken: 'OrderProcessingPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        params: transitionParams,
        transition: transitionName,
      }
    : {
        params: transitionParams,
        processAlias,
        transition: transitionName,
      };

  const queryParams = {
    expand: true,
    include: ['provider'],
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);

    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }

    const tx = entities[0];
    dispatch(speculateStripePaymentSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-stripe-payment-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...orderData,
    });

    return dispatch(speculateStripePaymentError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    return transitionPrivileged({
      bodyParams,
      isSpeculative: true,
      orderData,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    return initiatePrivileged({
      bodyParams,
      isSpeculative: true,
      orderData,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};
