import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconInfo.module.css';

const IconQuestion = props => {
  const { className, colour = '#413c3c', rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        className={css.marketplaceColorStroke}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M0.75 8C0.75 3.99594 3.99594 0.75 8 0.75C12.0041 0.75 15.25 3.99594 15.25 8C15.25 12.0041 12.0041 15.25 8 15.25C3.99594 15.25 0.75 12.0041 0.75 8Z"
          stroke={colour}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path d="M8 6.84998L8 12.25" stroke={colour} strokeWidth="1.5" />
        <path
          d="M7.10001 4.15C7.10001 3.65294 7.50295 3.25 8.00001 3.25C8.49706 3.25 8.90001 3.65294 8.90001 4.15C8.90001 4.64706 8.49706 5.05 8.00001 5.05C7.50295 5.05 7.10001 4.64706 7.10001 4.15Z"
          fill={colour}
          stroke="none"
        />
      </g>
    </svg>
  );
};

IconQuestion.defaultProps = {
  className: null,
  rootClassName: null,
};

IconQuestion.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconQuestion;
