import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { object, string } from 'prop-types';
import classNames from 'classnames';

import css from './Specs.module.css';
import { formatMoneyFromString } from '../../util/money';

const Spec = props => {
  const { id, spec } = props;

  switch (id) {
    case 'additionalInfo':
      return null;

    case 'distance':
      return (
        <li className={css.specTextContainer}>
          <b>Distance travelled</b>
          <p>{spec || 'Not specified'}</p>
        </li>
      );

    case 'hasPreviousOwners':
      return (
        <li className={css.specTextContainer}>
          <b>Has previous owners?</b>
          <p>{spec === 'yes' ? 'Yes' : spec === 'no' ? 'No' : 'Not specified'}</p>
        </li>
      );

    case 'lastService':
      return (
        <li className={css.specTextContainer}>
          <b>Last service date</b>
          <p>{spec ? new Date(spec).toLocaleString().split(',')[0] : 'Not specified'}</p>
        </li>
      );

    case 'rrp':
      return (
        <li className={css.specTextContainer}>
          <b>Original RRP</b>
          <p>{spec ? formatMoneyFromString(spec) : 'Not specified'}</p>
        </li>
      );

    default:
      return null;
  }
};

const SpecsComponent = props => {
  const { className, intl, specs, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {Object.entries(specs).map(([key, value]) => (
        <Spec id={key} intl={intl} key={`Spec_${key}`} spec={value} />
      ))}
    </ul>
  );
};

SpecsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  specs: [],
};

SpecsComponent.propTypes = {
  className: string,
  rootClassName: string,
  specs: object,
  intl: intlShape.isRequired,
};

const Specs = injectIntl(SpecsComponent);

export default Specs;
